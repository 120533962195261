@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Atkinson';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/Atkinson-Hyperlegible-Regular-102.woff) format('woff');
}

@font-face {
  font-family: 'AtkinsonBold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/Atkinson-Hyperlegible-Bold-102.woff) format('woff');
}

@font-face {
  font-family: 'OdilBold';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./assets/odil-bold.woff) format('woff');
}


#nav-bar {
  position: fixed;
  top: 0px;
  height: calc(100vh);
  width: 100%;
  background: #121212;
  z-index: 10;
  top: 0px;
  transform: translateX(-100vw);
  touch-action: none;
}

.nav-bar-show {
  animation: slideIn 0.25s forwards ease-out;
}

.nav-bar-hide {
  animation: slideOut 0.25s forwards ease-out;
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }
  to {
      transform: translateX(-100vw);
  }
}

@keyframes slideIn {
  from {
      transform: translateX(-100vw);
  }
  to {
      transform: translateX(0vw);
  }
}
